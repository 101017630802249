export default Object.freeze({
  list: null,
  refEl: null,
  cutElement: null,

  selectedItem: null,
  selectedTab: null,
  markerEnabled: false,

  marker: null,
});
