import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const areasRequest = createRequest(constants.areas);
export const areasSuccess = createSuccess(constants.areas);
export const areasFailure = createFailure(constants.areas);

export const getAreaRequest = createRequest(constants.area);
export const getAreaSuccess = createSuccess(constants.area);
export const getAreaFailure = createFailure(constants.area);

export const createAreaRequest = createRequest(`${constants.area}_CREATE`);
export const createAreaSuccess = createSuccess(`${constants.area}_CREATE`);
export const createAreaFailure = createFailure(`${constants.area}_CREATE`);

export const makeMasterCheckpointsRequest = createRequest(`${constants.area}_ALL_MASTER`);
export const makeMasterCheckpointsSuccess = createSuccess(`${constants.area}_ALL_MASTER`);
export const makeMasterCheckpointsFailure = createFailure(`${constants.area}_ALL_MASTER`);

export const removeMasterCheckpointsRequest = createRequest(`${constants.area}_REMOVE_ALL_MASTER`);
export const removeMasterCheckpointsSuccess = createSuccess(`${constants.area}_REMOVE_ALL_MASTER`);
export const removeMasterCheckpointsFailure = createFailure(`${constants.area}_REMOVE_ALL_MASTER`);

export const renameAreaRequest = createRequest(`${constants.area}_RENAME`);
export const renameAreaSuccess = createSuccess(`${constants.area}_RENAME`);
export const renameAreaFailure = createFailure(`${constants.area}_RENAME`);

export const updateAreaPositionRequest = createRequest(`${constants.area}_UPDATE_POSITION`);
export const updateAreaPositionSuccess = createSuccess(`${constants.area}_UPDATE_POSITION`);
export const updateAreaPositionFailure = createFailure(`${constants.area}_UPDATE_POSITION`);

export const areaStatusRequest = createRequest(`${constants.area}_STATUS_AREA`);
export const areaStatusSuccess = createSuccess(`${constants.area}_STATUS_AREA`);
export const areaStatusFailure = createFailure(`${constants.area}_STATUS_AREA`);

export const deleteAreaRequest = createRequest(`${constants.area}_DELETE_AREA`);
export const deleteAreaSuccess = createSuccess(`${constants.area}_DELETE_AREA`);
export const deleteAreaFailure = createFailure(`${constants.area}_DELETE_AREA`);

export const deleteCheckpointsRequest = createRequest(`${constants.area}_DELETE_ALL_CHECKPOINTS`);
export const deleteCheckpointsSuccess = createSuccess(`${constants.area}_DELETE_ALL_CHECKPOINTS`);
export const deleteCheckpointsFailure = createFailure(`${constants.area}_DELETE_ALL_CHECKPOINTS`);

export const updateNoteRequest = createRequest(`${constants.area}_UPDATE_CHECKPOINT`);
export const updateNoteSuccess = createSuccess(`${constants.area}_UPDATE_CHECKPOINT`);
export const updateNoteFailure = createFailure(`${constants.area}_UPDATE_CHECKPOINT`);

export const addMarkerRequest = createRequest(`${constants.area}_ADD_MARKER`);
export const addMarkerSuccess = createSuccess(`${constants.area}_ADD_MARKER`);
export const addMarkerFailure = createFailure(`${constants.area}_ADD_MARKER`);

export const deleteMarkerRequest = createRequest(`${constants.area}_DELETE_MARKER`);
export const deleteMarkerSuccess = createSuccess(`${constants.area}_DELETE_MARKER`);
export const deleteMarkerFailure = createFailure(`${constants.area}_DELETE_MARKER`);

export const requestAreas = areasRequest;
export const requestArea = getAreaRequest;
export const requestCreateArea = createAreaRequest;
export const requestMakeAllMasterCheckpoint = makeMasterCheckpointsRequest;
export const requestRemoveAllMasterCheckpoint = removeMasterCheckpointsRequest;
export const requestRenameArea = renameAreaRequest;
export const requestUpdateAreaPosition = updateAreaPositionRequest;
export const updateAreaStatus = areaStatusRequest;
export const deleteArea = deleteAreaRequest;
export const deleteCheckpoints = deleteCheckpointsRequest;
export const updateNote = updateNoteRequest;
export const addMarker = addMarkerRequest;
export const deleteMarker = deleteMarkerRequest;
