import { SITES } from 'app/api-routes';
import axios from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { siteInfoCPFailure, siteInfoCPRequest, siteInfoCPSuccess } from './actions';

function* siteInfoCPSaga({ payload: siteID }) {
  try {
    const { payload: siteInfoCP } = yield axios.get(`${SITES}/${siteID}/checkpoints`, {
      params: {
        includeInactive: true
      }
    });

    yield put(siteInfoCPSuccess(siteInfoCP));
  } catch (err) {
    yield put(siteInfoCPFailure(err));
  }
}

export default function*() {
  yield all([yield takeLatest(siteInfoCPRequest, siteInfoCPSaga)]);
}
