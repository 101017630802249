import preparedReducer from './reducer';

import {
  requestCheckpoints,
  requestCheckpointInfo,
  requestCreateCheckpoint,
  renameCheckpoint,
  makeMasterCheckpoint,
  removeMasterCheckpoint,
  enableGPSCheckpoint,
  removeGPSCheckpoint,
  enableCheckpoint,
  disableCheckpoint,
  deleteCheckpoint,
  updateCheckpointPosition,
  updateNote,
  updateReminders,
  addCheckpointMarker,
  deleteCheckpointMarker,
  enablePhotoOnScanCheckpoint,
  deletePhotoOnScanCheckpoint,
  requestCheckpointQRCodeData,
  updateCheckpointQRTag,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'checkpoints';

export const selectors = (state) => state[SLICE_NAME];

export const getCheckpointInfo = (state, checkpointID) => state.checkpoints.info[checkpointID];

export const selectCheckpointQRData = (state, checkpointID) => state[SLICE_NAME].qrData[checkpointID];

export const actions = {
  requestCheckpoints,
  requestCheckpointInfo,
  requestCreateCheckpoint,
  renameCheckpoint,
  makeMasterCheckpoint,
  removeMasterCheckpoint,
  enableGPSCheckpoint,
  removeGPSCheckpoint,
  enableCheckpoint,
  disableCheckpoint,
  deleteCheckpoint,
  updateCheckpointPosition,
  updateNote,
  updateReminders,
  addCheckpointMarker,
  deleteCheckpointMarker,
  enablePhotoOnScanCheckpoint,
  deletePhotoOnScanCheckpoint,
  requestCheckpointQRCodeData,
  updateCheckpointQRTag,
};
