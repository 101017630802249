const ROUTE_PREFIX = '/api';

const createApiRoute = (route) => `${ROUTE_PREFIX}/${route}`;

/** --- AUTH --- */

// Get list of clients.
export const SESSIONS = createApiRoute('sessions');

export const ACCOUNT_INFO = createApiRoute('sessions/account-info');

/** AUTH */

/** --- CLIENTS --- */

// Get list of clients.
export const CLIENTS = createApiRoute('clients');

/** CLIENTS */

/** --- ACTIVITIES --- */

// Get list of activities.
export const ACTIVITIES = createApiRoute('activitylog');
// Get activities by tour.
export const ACTIVITIES_BY_TOUR = createApiRoute('activitylog/by-tourlog');
// Get activities by visits.
export const ACTIVITIES_BY_VISITS = createApiRoute('activitylog/by-visit');
// Get activities by site.
export const ACTIVITIES_BY_SITE = createApiRoute('activitylog/by-site');

/** ACTIVITIES */

/** --- CODES --- */

// Get list of codes.
export const CODES = createApiRoute('codes');

/** CODES */

/** --- DASHBOARD (activity monitoring) --- */

// Get list of activity monitoring.
export const DASHBOARD = createApiRoute('dashboard/now');
export const DASHBOARD_V2 = createApiRoute('v2/dashboard/now');

/** DASHBOARD */

/** --- SITES --- */

// Get list of sites.
export const SITES = createApiRoute('sites');
// Get list of addresses.
export const SITE_ADDRESSES = createApiRoute('sites/address');

/** SITES */

/** --- SITES V2 --- */

export const SITES_V2 = createApiRoute('v2/sites');

/** SITES V2 */

/** --- ACTIVITY MAP --- */

export const ACTIVITY_MAP = createApiRoute('v2/activity-map');

/** ACTIVITY MAP */

/** --- EMPLOYEES --- */

// Get list of employees.
export const EMPLOYEES = createApiRoute('employees');

/** EMPLOYEES */

/** --- CONTACTS --- */

// Get list of employees.
export const CONTACTS = createApiRoute('contacts');

/** CONTACTS */

/** --- MOBILE PATROLS --- */

// Get list of mobile patrols.
export const MOBILE_PATROL = createApiRoute('mobile-patrols');

/** MOBILE PATROLS */

/** --- MOBILE PATROLS V2 --- */

// Get list of mobile patrols.
export const MOBILE_PATROLS_V2 = createApiRoute('v2/mobile-patrols');

/** MOBILE PATROLS V2 */

/** --- SHIFTS --- */

// Get shifts.
export const SHIFTS = createApiRoute('shifts');

/** SHIFTS */

/** --- CHECKPOINTS --- */

// Get checkpoints.
export const CHECKPOINTS = createApiRoute('checkpoints');

/** CHECKPOINTS */

/** --- TASKS --- */

// Get tasks.
export const TASKS = createApiRoute('tasks');
export const TASKS_V2 = createApiRoute('v2/tasks');

/** TASKS */

/** --- TASK_LOG --- */

export const TASK_LOG = createApiRoute('task-log');

/** TASK_LOG */

/** --- NOTEBOOK --- */

// Get notebooks.
export const NOTEBOOK = createApiRoute('notebook');

/** NOTEBOOK */

/** --- SHIFTS_VIEWER --- */

// Get shifts viewer list.
export const SHIFTS_VIEWER = createApiRoute('shifts-viewer');

/** SHIFTS_VIEWER */

/** --- TIMESHEETS --- */

// Get timeshift list by client.
export const TIMESHEETS_BY_CLIENT = createApiRoute('timesheets/by-client');
export const TIMESHEETS_BY_EMPLOYEE = createApiRoute('timesheets/by-employee');
export const TIMESHEETS_BY_SITE = createApiRoute('timesheets/by-site');
export const TIMESHEETS_BY_MOBILE_PATROL = createApiRoute('timesheets/by-mobile-patrol');

/** TIMESHIFTS */

/** --- INCIDENT_REPORTS --- */

// Get incident_reports list.
export const INCIDENT_REPORTS = createApiRoute('incident_reports');
export const INCIDENT_REPORTS_V2 = createApiRoute('v2/incident_reports');

/** INCIDENT_REPORTS */

/** --- USERS --- */

export const USERS = createApiRoute('users');

/** USERS */

/** --- MEDIA_ASSETS --- */

export const mediaAssets = createApiRoute('mediaassets');
export const uploadMediaAssets = createApiRoute('mediaassets/upload');

/** MEDIA_ASSETS */

/** --- NOTE_TYPES --- */

export const NOTE_TYPES = createApiRoute('notetypes');

/** NOTE_TYPES */

/** --- BILLING --- */

export const BILLING_HISTORY = createApiRoute('billing/history');

/** BILLING */

/** --- SYSTEM --- */

export const SYSTEM_USAGE = createApiRoute('system/usage');

/** SYSTEM */

/** --- AREAS --- */

export const AREAS = createApiRoute('areas');

/** AREAS */

/** --- ROLES --- */

export const ROLES = createApiRoute('roles');

/** ROLES */

/** --- TOURS --- */

export const TOURS = createApiRoute('tours');

/** TOURS */

/** --- TOUR LOGS --- */

export const TOUR_LOGS = createApiRoute('tourlogs');

/** TOUR LOGS */

/** --- INFO NOTES --- */

export const INFO_NOTES = createApiRoute('infonotes');

/** INFO NOTES */

/** --- INFO NOTES V2 --- */

export const INFO_NOTES_V2 = createApiRoute('v2/infonotes');

/** INFO NOTES V2 */

/** --- PERFORMANCE --- */

export const PERFORMANCE = createApiRoute('performance');

/** PERFORMANCE */

/** --- NOTIFICATIONS --- */

export const NOTIFICATIONS = createApiRoute('notifications');

/** NOTIFICATIONS */

/** --- FORMS --- */

export const FORMS = createApiRoute('forms');

/** FORMS */

/** --- SHIFT LOG V2 --- */

export const SHIFT_LOG = createApiRoute('v2/shift-log');

/** SHIFT LOG V2 */

/** --- HELP --- */

export const HELP = createApiRoute('v2/common/help');

/** HELP */

/** --- VISITS --- */

export const VISITS = createApiRoute('visits');

/** VISITS */

/** --- ATTACHMENTS --- */

export const ATTACHMENTS = createApiRoute('attachments');

/** ATTACHMENTS */

/** --- PROFILES --- */

export const PROFILES = createApiRoute('profiles');

/** PROFILES */

/** --- SCHEDULES --- */

export const SCHEDULES = createApiRoute('schedules');

/** SCHEDULES */

/** --- STATISTICS --- */

export const STATISTICS = createApiRoute('statistics');

/** STATISTICS */

/** --- AUDIT --- */

export const AUDIT = createApiRoute('audit');

/** AUDIT */

/** --- COMPANIES --- */

export const COMPANIES = createApiRoute('companies');

/** COMPANIES */

/** --- VISITORS --- */

export const VISITORS = createApiRoute('visitors');

/** VISITORS */

/** --- VISITOR_LOG --- */

export const VISITOR_LOG = createApiRoute('visitor-log');

/** VISITOR_LOG */

/** --- ANALYTICS --- */

export const ENTITIES_SUMMARY = createApiRoute('analytics/entities-summary');
export const EVENTS_SUMMARY = createApiRoute('analytics/events-summary');
export const ENTITY_OVERVIEW = createApiRoute('analytics/entity-overview');
export const EVENT_OVERVIEW = createApiRoute('analytics/event-overview');

/** --- ANALYTICS --- */
