import { createAction } from 'ducks/helpers';
import constants from './constants';

export const updateFilterState = createAction(`${constants.pageStore}_FILTER_STATE`);

export const updateActivityFilter = createAction(`${constants.pageStore}_FILTER_ACTIVITY`);
export const updateInfoBoardFilter = createAction(`${constants.pageStore}_INFO_BOARD`);
export const updatePerformanceFilter = createAction(`${constants.pageStore}_FILTER_PERFORMANCE`);
export const updateActivityMapFilter = createAction(`${constants.pageStore}_FILTER_ACTIVITY_MAP`);
export const updateIRFilter = createAction(`${constants.pageStore}_FILTER_IR`);
export const updateMonitoringFilter = createAction(`${constants.pageStore}_FILTER_MONITORING`);
export const updateWorkHistoryFilter = createAction(`${constants.pageStore}_FILTER_WORK_HISTORY`);
export const updateTimesheetsFilter = createAction(`${constants.pageStore}_FILTER_TIMESHEETS`);
export const updateClientDashboardFilter = createAction(`${constants.pageStore}_FILTER_CLIENT_DASHBOARD`);
export const updateAdminClientFilter = createAction(`${constants.pageStore}_FILTER_ADMIN_CLIENT`);
export const updateAdminSiteFilter = createAction(`${constants.pageStore}_FILTER_ADMIN_SITE`);
export const updateAdminEmployeeFilter = createAction(`${constants.pageStore}_FILTER_ADMIN_EMPLOYEE`);
export const updateAdminContactFilter = createAction(`${constants.pageStore}_FILTER_ADMIN_CONTACT`);
export const updateMobileUnitsFilter = createAction(`${constants.pageStore}_FILTER_ADMIN_MOBILE_UNITS`);
export const updateFormResultsFilter = createAction(`${constants.pageStore}_FILTER_ADMIN_FORM_RESULTS`);
export const updateFormsFilter = createAction(`${constants.pageStore}_FILTER_ADMIN_FORMS`);
export const updateTasksFilter = createAction(`${constants.pageStore}_FILTER_ADMIN_TASKS`);
export const updateToursFilter = createAction(`${constants.pageStore}_FILTER_ADMIN_TOURS`);
export const updateVisitorsFilter = createAction(`${constants.pageStore}_FILTER_ADMIN_VISITORS`);
export const updateSystemLogFilter = createAction(`${constants.pageStore}_FILTER_ADMIN_SYSTEM_LOG`);
export const updateVisitorLogFilter = createAction(`${constants.pageStore}_FILTER_ADMIN_VISITOR_LOG`);
export const updateBIFilter = createAction(`${constants.pageStore}_FILTER_BI`);

export const updateTab = createAction(`${constants.pageStore}_TAB`);
export const updateSort = createAction(`${constants.pageStore}_SORT`);

export const setIsFilterSelected = createAction(`${constants.pageStore}_IS_FILTER_SELECTED`);

export const reset = createAction(`${constants.pageStore}_RESET`);
