import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => createStyles(
  {
    formContainer: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '& form': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    },
    formRoot: {
      maxWidth: 442,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: 32,
      },
    },
    actions: {
      marginTop: theme.spacing(3.5),
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1.5),
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    actionButton: {
      height: 48,
    },
    form: {
      marginTop: theme.spacing(3),
      width: '100%',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1.5),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }
    },
    input: {
      marginTop: theme.spacing(2),
    },
    actionLeft: {
      width: '50%',
      paddingRight: 8,
      [theme.breakpoints.down('lg')]: {
        paddingRight: 4,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        paddingRight: 0,
      },
    },
    actionRight: {
      width: '100%',
      '&.old': {
        width: '50% !important',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        paddingTop: theme.spacing(1),
      },
    }
  }
), {
  name: 'SignInPage'
});

export { useStyles };
