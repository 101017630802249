export const NEW = 'new';
export const ALL = 'all';
export const PHONE_NO_REGEX = /^[0-9\- ]{8,14}$/;
export const ACTIVE_STATUS = 'A';

export const SUPERUSER_LOGIN_NAME = 'mobileo';

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_TIME_FORMAT_SHORT = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT_SHORT = 'HH:mm';

export const SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

export const CLIENT_TYPE = 'C';

export const IR_STATUSES = {
  NEW: 'New',
  APPROVED: 'Approved',
};

export const UNIT_TYPES = {
  SITES: 'sites',
  EMPLOYEES: 'employees',
  MOBILE_PATROLS: 'mobile_patrols',
};

export const CHARTS_TYPES = {
  ENTITY: 'entity',
  EVENT: 'event',
}

export const CHECKPOINT_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  NO_TAG_ASSIGNED: 'no_tag_assigned',
  MASTER: 'master',
};

export const TABLE_NAMES = {
  EMPLOYEES: 'employees',
  CONTACTS: 'contacts',
  SITES: 'sites',
  CLIENTS: 'clients',
  TOURS: 'tours',
  TASKS: 'tasks',
  VISITORS: 'visitors',
  VISITOR_LOG: 'visitor_log',
  MOBILE_PATROLS: 'mobile_patrols',
};

export const TOUR_TYPES = {
  STATIC: 'static',
  DYNAMIC: 'dynamic',
};

export const SITE_PLAN_ITEM_TYPES = {
  AREA: 'area',
  CHECKPOINT: 'checkpoint',
  SITE: 'site',
};

export const REPEAT_TYPES = {
  NEVER: 'never',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

export const SCHEDULE_MONTH_TYPE = {
  DAY_OF_MONTH: 'day_of_month',
  WEEKDAY_OF_MONTH: 'weekday_of_month',
};

export const SHIFT_STATUSES = {
  IN_PROGRESS: 'shift_in_progress',
  TERMINATED: 'shift_terminated',
  ENDED: 'shift_ended',
};

export const ENTITY_SITE_TYPE = {
  SINGLE_SITE: 'SINGLE_SITE',
  MULTIPLE_SITES: 'MULTIPLE_SITES',
};

export const ENTITY_STATUS = {
  ACTIVE: 'A',
  SUSPENDED: 'S',
  DELETED: 'D',
  NEW: 'N',
};

export const FORM_RESULTS_STATUSES = {
  SUBMITTED: 'submitted',
  UNSUBMITTED: 'unsubmitted',
};

export const VISIT_REGISTRATION_TYPES = {
  ENTRANCE_ONLY: 'entrance_only',
  ENTRANCE_AND_EXIT: 'entrance_and_exit',
};

export const PREDEFINED_RANGES = {
  THIS_YEAR: 'this_year',
  LAST_YEAR: 'last_year',
  THIS_MONTH: 'this_month',
  LAST_MONTH: 'last_month',
};

export const NO_QR_CODE_STATUS = 'no_qr_code';

export const EMAIL_NOTIFICATIONS_TAB_RELEASE_DATE = '2023-10-17';
export const EMPLOYEE_PROFILE_RELEASE_DATE = '2023-11-20';

export const HIDE_PROFESSIONAL_TAB_ACCOUNTS = ['plutronix', 'qns', 'mobileo'];
export const EMPLOYEE_ID_ACCOUNTS = ['mobileotest', 'gcdemo', 'ggseguridad'];
