import IntlMessages from 'util/IntlMessages';
import React from 'react';
import {
  Admin,
  Analytics,
  Managment,
  Monitoring,
  Operations,
  ControlPanelIcon,
  MonitorIcon,
  ActivityMap,
  IncidentReportIcon,
  ActivityLog,
  WorkHistory,
  TimesheetIcon,
  GroupIcon,
  SitesIcon,
  ClientsIcon,
  MobileUnitsIcon,
  SecurityIcon,
  PaperIcon,
  ControlPanel,
  InfoIcon,
  UserManualIcon,
  SettingsWhilsIcon,
  PerformanceIcon,
  InfoboardIcon,
  FormIcon,
  ContactIcon,
  CheckboxChecked,
  TourIcon,
  DatabaseIcon,
  VisitorsIcon,
  PropertyIcon,
  VisitorEntranceExitIcon,
} from 'assets/icons';
import { BASE_APP_PATH } from 'new-design/routes/common/constants';
import { usePageId } from 'new-design/hooks/usePageId';

export const useTopMenu = () => {
  const { hashId } = usePageId();

  return [
    {
      name: <IntlMessages id="topMenu_monitoring" />,
      type: 'collapse',
      icon: <Monitoring />,
      children: [
        {
          name: <IntlMessages id="topMenu_monitoring_control_panel" />,
          type: 'item',
          link: `${BASE_APP_PATH}/control-panel`,
          icon: <ControlPanelIcon />,
          trancode: 'operations_dashboard_site',
        },
        {
          name: <IntlMessages id="topMenu_monitoring_activity_monitoring" />,
          type: 'item',
          link: `${BASE_APP_PATH}/activity-monitor`,
          icon: <MonitorIcon />,
          trancode: 'operations_dashboard',
          subpages: [
            {
              link: `${BASE_APP_PATH}/activity-monitor/shift-started`,
              name: <IntlMessages id="cp.shift.shift_started" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-monitor/shift-end`,
              name: <IntlMessages id="cp.shift.shift_ended" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-monitor/checkpoint-scanned`,
              name: <IntlMessages id="checkpoint_scanned" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-monitor/task-completed`,
              name: <IntlMessages id="task_completed" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-monitor/note-created`,
              name: <IntlMessages id="note_created" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-monitor/info-note-created`,
              name: <IntlMessages id="info_note_created" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-monitor/info-note-ack`,
              name: <IntlMessages id="info_note_ack" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-monitor/tour`,
              name: <IntlMessages id="tour" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-monitor/visit`,
              name: <IntlMessages id="site_visit" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-monitor/gps-alert`,
              name: <IntlMessages id="gps_alert" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-monitor/no-activity`,
              name: <IntlMessages id="no_activity" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-monitor/alarm`,
              name: <IntlMessages id="alarm" />,
            },
          ],
        },
        {
          name: <IntlMessages id="topMenu_monitoring_activity_map" />,
          type: 'item',
          link: `${BASE_APP_PATH}/activity-map`,
          icon: <ActivityMap />,
          trancode: 'operations_dashboard_site',
        },
      ],
    },
    {
      name: <IntlMessages id="topMenu_operations" />,
      type: 'collapse',
      icon: <Operations />,
      children: [
        {
          name: <IntlMessages id="topMenu_analytics_activity_log" />,
          type: 'item',
          link: `${BASE_APP_PATH}/activity-log`,
          icon: <ActivityLog />,
          trancode: 'events_history',
          subpages: [
            {
              link: `${BASE_APP_PATH}/activity-log/shift-started`,
              name: <IntlMessages id="cp.shift.shift_started" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-log/shift-end`,
              name: <IntlMessages id="cp.shift.shift_ended" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-log/checkpoint-scanned`,
              name: <IntlMessages id="checkpoint_scanned" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-log/task-completed`,
              name: <IntlMessages id="task_completed" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-log/note-created`,
              name: <IntlMessages id="note_created" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-log/info-note-created`,
              name: <IntlMessages id="info_note_created" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-log/info-note-ack`,
              name: <IntlMessages id="info_note_ack" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-log/tour`,
              name: <IntlMessages id="tour" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-log/visit`,
              name: <IntlMessages id="site_visit" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-log/gps-alert`,
              name: <IntlMessages id="gps_alert" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-log/no-activity`,
              name: <IntlMessages id="no_activity" />,
            },
            {
              link: `${BASE_APP_PATH}/activity-log/alarm`,
              name: <IntlMessages id="alarm" />,
            },
          ],
        },
        {
          name: <IntlMessages id="forms" />,
          type: 'item',
          link: `${BASE_APP_PATH}/form-results`,
          icon: <FormIcon />,
          trancode: 'form_results_menu',
          subpages: [
            {
              link: `${BASE_APP_PATH}/form-results/:id/fill`,
              name: <IntlMessages id="fill_form" />,
            },
            {
              link: `${BASE_APP_PATH}/form-results/:id/view`,
              name: <IntlMessages id="view_form" />,
            },
          ],
        },
        {
          name: <IntlMessages id="topMenu_operations_info_board" />,
          type: 'item',
          link: `${BASE_APP_PATH}/info-board`,
          icon: <InfoboardIcon />,
          trancode: 'events_history',
          subpages: [
            {
              link: `${BASE_APP_PATH}/info-board/:id`,
              name: <IntlMessages id="info_note_edit" />,
            },
          ],
        },
        {
          name: <IntlMessages id="topMenu_analytics_incident_reports" />,
          type: 'item',
          link: `${BASE_APP_PATH}/incident-reports`,
          historyBack: true,
          icon: <IncidentReportIcon />,
          trancode: 'incident_reports',
          subpages: [
            {
              link: `${BASE_APP_PATH}/incident-reports/new`,
              name: <IntlMessages id="new_incident_reports" />,
            },
            {
              link: `${BASE_APP_PATH}/incident-reports/:id`,
              name: <IntlMessages id="edit_incident_reports" />,
            },
            {
              link: `${BASE_APP_PATH}/incident-reports/:id/view`,
              name: <IntlMessages id="view_incident_reports" />,
            },
          ],
        },
        {
          name: <IntlMessages id="visitor_log" />,
          type: 'item',
          link: `${BASE_APP_PATH}/visitor-log`,
          icon: <VisitorsIcon />,
          trancode: 'visitor_log_menu',
          subpages: [
            {
              link: `${BASE_APP_PATH}/visitor-log/register`,
              name: <IntlMessages id="register_visit" />,
            },
            {
              link: `${BASE_APP_PATH}/visitor-log/:id`,
            },
          ],
        },
      ],
    },
    {
      name: <IntlMessages id="topMenu_analytics" />,
      type: 'collapse',
      icon: <Analytics />,
      children: [
        {
          name: <IntlMessages id="bi_and_statistics" />,
          type: 'item',
          link: `${BASE_APP_PATH}/bi`,
          icon: <PropertyIcon />,
          trancode: 'bi_and_statistics_menu',
        },
        {
          name: <IntlMessages id="topMenu_analytics_performance" />,
          type: 'item',
          link: `${BASE_APP_PATH}/performance`,
          icon: <PerformanceIcon />,
          trancode: 'performance',
        },
        {
          name: <IntlMessages id="topMenu_analytics_work_history" />,
          type: 'item',
          link: `${BASE_APP_PATH}/work-history`,
          icon: <WorkHistory />,
          trancode: 'events_history',
          subpages: [
            {
              link: `${BASE_APP_PATH}/work-history/shift#shiftId=${hashId}`,
              name: <IntlMessages id="cp.shift.shift_details" />,
              subpages: [
                {
                  link: `${BASE_APP_PATH}/work-history/shift/logs#id=${hashId}`,
                  name: <IntlMessages id="shift_log" />,
                },
              ],
            },
          ],
        },
        {
          name: <IntlMessages id="topMenu_analytics_timesheets" />,
          type: 'item',
          link: `${BASE_APP_PATH}/timesheets`,
          icon: <TimesheetIcon />,
          trancode: 'timesheets',
        },
      ],
    },
    {
      name: <IntlMessages id="topMenu_managment" />,
      type: 'collapse',
      icon: <Managment />,
      children: [
        {
          name: <IntlMessages id="clients" />,
          type: 'item',
          link: `${BASE_APP_PATH}/clients`,
          icon: <ClientsIcon />,
          trancode: 'client_search',
          subpages: [
            {
              link: `${BASE_APP_PATH}/clients/:id`,
            },
          ],
        },
        {
          name: <IntlMessages id="contacts" />,
          type: 'item',
          link: `${BASE_APP_PATH}/contacts`,
          icon: <ContactIcon />,
          trancode: 'client_search',
          subpages: [
            {
              link: `${BASE_APP_PATH}/contacts/:id`,
            },
          ],
        },
        {
          name: <IntlMessages id="sites" />,
          type: 'item',
          link: `${BASE_APP_PATH}/sites`,
          icon: <SitesIcon />,
          trancode: 'site_search',
          subpages: [
            {
              link: `${BASE_APP_PATH}/sites/:id`,
            },
          ],
        },
        {
          name: <IntlMessages id="tasks" />,
          type: 'item',
          link: `${BASE_APP_PATH}/tasks`,
          icon: <CheckboxChecked />,
          trancode: 'manage_tasks',
          subpages: [
            {
              link: `${BASE_APP_PATH}/tasks/:id`,
            },
          ],
        },
        {
          name: <IntlMessages id="tours" />,
          type: 'item',
          link: `${BASE_APP_PATH}/tours`,
          icon: <TourIcon />,
          trancode: 'site_search',
          subpages: [
            {
              link: `${BASE_APP_PATH}/tours/:id`,
            },
          ],
        },
        {
          name: <IntlMessages id="mobile_units" />,
          type: 'item',
          link: `${BASE_APP_PATH}/mobile-units`,
          icon: <MobileUnitsIcon />,
          trancode: 'mobile_patrol_search',
          subpages: [
            {
              link: `${BASE_APP_PATH}/mobile-units/:id`,
            },
          ],
        },
        {
          name: <IntlMessages id="cp.dashboard.employees" />,
          type: 'item',
          link: `${BASE_APP_PATH}/employees`,
          icon: <GroupIcon />,
          trancode: 'employee_search',
          subpages: [
            {
              link: `${BASE_APP_PATH}/employees/:id`,
            },
          ],
        },
        {
          name: <IntlMessages id="visitors" />,
          type: 'item',
          link: `${BASE_APP_PATH}/visitors`,
          icon: <VisitorsIcon />,
          trancode: 'visitors_menu',
          subpages: [
            {
              link: `${BASE_APP_PATH}/visitors/:id`,
            },
          ],
        },
      ],
    },
    {
      name: <IntlMessages id="topMenu_admin" />,
      type: 'collapse',
      icon: <Admin />,
      children: [
        {
          name: <IntlMessages id="my_account" />,
          type: 'item',
          link: `${BASE_APP_PATH}/my-account`,
          icon: <SecurityIcon />,
          trancode: 'my_account',
        },
        {
          name: <IntlMessages id="forms" />,
          type: 'item',
          link: `${BASE_APP_PATH}/forms`,
          icon: <FormIcon />,
          trancode: 'forms',
          subpages: [
            {
              link: `${BASE_APP_PATH}/forms/:id`,
            },
          ],
        },
        {
          name: <IntlMessages id="settings" />,
          type: 'item',
          link: `${BASE_APP_PATH}/settings`,
          icon: <SettingsWhilsIcon />,
          trancode: 'settings',
        },
        {
          name: <IntlMessages id="system_log" />,
          type: 'item',
          link: `${BASE_APP_PATH}/system-log`,
          icon: <DatabaseIcon />,
          trancode: 'settings',
          subpages: [
            {
              link: `${BASE_APP_PATH}/system-log/:id`,
            },
          ],
        },
      ],
    },
    {
      name: <IntlMessages id="information" />,
      type: 'collapse',
      icon: <InfoIcon />,
      children: [
        {
          name: <IntlMessages id="user_manual" />,
          type: 'item',
          link: `${BASE_APP_PATH}/user-manual`,
          icon: <UserManualIcon />,
          trancode: 'settings',
        },
        {
          name: <IntlMessages id="cp.footer.terms" />,
          type: 'item',
          link: `${BASE_APP_PATH}/terms`,
          icon: <PaperIcon />,
          trancode: 'settings',
        },
        {
          name: <IntlMessages id="cp.footer.policy" />,
          type: 'item',
          link: `${BASE_APP_PATH}/policy`,
          icon: <PaperIcon />,
          trancode: 'settings',
        },
      ],
    },
  ];
};

export const topClientMenu = [
  {
    name: <IntlMessages id="cp.dashboard.dashboard" />,
    type: 'collapse',
    link: `${BASE_APP_PATH}/control-panel`,
    icon: <ControlPanel />,
    trancode: 'operations_dashboard_site',
    children: [],
  },
  {
    name: <IntlMessages id="topMenu_analytics_activity_log" />,
    type: 'collapse',
    icon: <ActivityLog />,
    link: `${BASE_APP_PATH}/activity-log`,
    trancode: 'site_activity_by_date',
    children: [],
  },
  {
    name: <IntlMessages id="topMenu_analytics_incident_reports" />,
    type: 'item',
    link: `${BASE_APP_PATH}/incident-reports`,
    icon: <IncidentReportIcon />,
    trancode: 'events_history',
    children: [],
  },
  {
    name: <IntlMessages id="forms" />,
    type: 'item',
    link: `${BASE_APP_PATH}/form-results`,
    icon: <FormIcon />,
    trancode: 'form_results_menu',
    children: [],
  },
  {
    name: <IntlMessages id="visitor_log" />,
    type: 'collapse',
    icon: <VisitorsIcon />,
    trancode: 'visitor_log_menu',
    children: [
      {
        name: <IntlMessages id="visitors" />,
        type: 'item',
        link: `${BASE_APP_PATH}/visitors`,
        icon: <VisitorsIcon />,
        trancode: 'visitors_menu',
      },
      {
        name: <IntlMessages id="visits" />,
        type: 'item',
        link: `${BASE_APP_PATH}/visitor-log`,
        icon: <VisitorEntranceExitIcon />,
        trancode: 'visitor_log_menu',
      },
    ],
  },
];
