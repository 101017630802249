export const VISITOR_LOG_EVENT_TYPES = {
  ENTRANCE: 'visitor_entrance',
  EXIT: 'visitor_exit',
};

export const VISITOR_LOG_VISIT_FIELDS = {
  VISITEE: 'visitee',
  VISIT_PURPOSE: 'visit_purpose',
  VISIT_LOCATION: 'visit_location',
  COMMENTS: 'comments',
  PHOTOS: 'photos',
  CAR_PLATE: 'car_plate_number',
  CAR_MAKE: 'car_make',
  CAR_MODEL: 'car_model',
  CAR_COLOR: 'car_color',
};
