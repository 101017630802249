import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';

import * as auth from 'ducks/auth';
import * as credentials from 'ducks/credentials';
import { FormTextField, Form } from 'new-design/common/ui/form-inputs';

import IntlMessages from 'util/IntlMessages';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { SignInSchema } from './validationSchema';
import { CompanyLogo } from './components/CompanyLogo';
import { Submit } from './components/Submit';

import { useStyles } from './styles';

import { CompanyIcon, UserIcon, PasswordIcon } from 'assets/icons';

export const SignInForm = ({ setSubmit }) => {
  const classes = useStyles();
  const credentialsState = useSelector(credentials.selectors);
  const { login } = bindActionCreators(auth.actions, useDispatch());

  const onSubmit = useCallback(async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      localStorage.setItem('app', values.legacy ? 'legacy' : 'new');
      login({
        ...values,

        onFailure: () => setSubmitting(false),
      });
      // temp fix
      await setTimeout(() => {}, 2000);
    } catch {
      NotificationManager.error(<IntlMessages id="request_error" />);
      setSubmitting(false);
    }
  }, []);

  return (
    <Box className={classes.formContainer}>
      <Form
        initialValues={{
          legacy: false,
          ...credentialsState,
        }}
        validationSchema={SignInSchema}
        onSubmit={onSubmit}
        outerSubmit={setSubmit}
      >
        <Box className={classes.formRoot}>
          <CompanyLogo />
          <Box className={classes.form}>
            <FormTextField
              fullWidth
              name="company"
              autoFocus={!credentialsState.company}
              topLabel={<IntlMessages id="login_company_hint" />}
              className={classes.input}
              icon={<CompanyIcon />}
            />
            <FormTextField
              fullWidth
              name="user"
              topLabel={<IntlMessages id="login_user_hint" />}
              className={classes.input}
              icon={<UserIcon />}
            />
            <FormTextField
              fullWidth
              autoFocus={!!credentialsState.user}
              name="password"
              type="password"
              topLabel={<IntlMessages id="login_password_hint" />}
              className={classes.input}
              icon={<PasswordIcon />}
            />
            <Box className={classes.actions}>
              <Submit
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.actionButton}
              >
                <IntlMessages id="login" />
              </Submit>
            </Box>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};
